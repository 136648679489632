import React from 'react';

const SVG = ({
	style = {},
	fill = '#000',
	width = '17.5px',
	height = '17.5px',
	className = '',
	viewBox = '0 0 225 225',
}) => (
	<svg
		version='1.0'
		xmlns='http://www.w3.org/2000/svg'
		style={style}
		// width='200.000000pt'
		width={width}
		// height='190.000000pt'
		height={height}
		// viewBox='0 0 200.000000 190.000000'
		viewBox={viewBox}
		className={`svg-icon ${className || ''}`}
		preserveAspectRatio='xMidYMid meet'>
		<metadata>
			Created by potrace 1.15, written by Peter Selinger 2001-2017
		</metadata>
		<g
			transform='translate(0.000000,225.000000) scale(0.100000,-0.100000)'
			fill={fill}
			stroke='none'>
			<path
				fill={fill}
				d='M1075 2189 c-11 -6 -107 -97 -212 -202 l-192 -191 150 -64 c126 -53
155 -62 173 -53 11 6 39 12 60 13 39 3 39 3 154 181 l115 179 -59 60 c-84 85
-135 106 -189 77z'
			/>
			<path
				fill={fill}
				d='M1261 1825 c-149 -231 -152 -213 64 -307 215 -93 202 -99 236 107
l27 160 -107 108 -107 108 -113 -176z'
			/>
			<path
				fill={fill}
				d='M491 1616 l-124 -124 134 -195 c129 -189 134 -195 170 -199 l37 -3
130 200 c124 192 129 201 115 224 -8 13 -15 38 -15 56 l0 33 -157 66 c-86 36
-159 66 -162 66 -2 0 -60 -56 -128 -124z'
			/>
			<path
				fill={fill}
				d='M1647 1704 c-14 -48 -39 -234 -34 -248 4 -9 15 -16 25 -16 14 0 199
74 209 84 1 1 -42 46 -96 100 -81 81 -100 95 -104 80z'
			/>
			<path
				fill={fill}
				d='M1134 1499 c-19 -21 -79 -43 -93 -35 -7 5 -88 -114 -258 -381 -11
-17 49 6 279 104 161 68 317 134 345 146 32 13 53 28 53 38 0 10 -49 36 -155
81 -148 63 -155 65 -171 47z'
			/>
			<path
				fill={fill}
				d='M1788 1422 c-60 -25 -105 -49 -100 -53 6 -6 421 -184 490 -210 21 -8
22 -7 11 14 -11 23 -280 298 -289 296 -3 0 -53 -21 -112 -47z'
			/>
			<path
				fill={fill}
				d='M188 1312 c-109 -111 -150 -163 -136 -177 5 -5 477 -105 494 -105 7
0 16 9 19 20 5 15 -25 65 -116 197 -67 98 -125 181 -129 185 -3 5 -63 -49
-132 -120z'
			/>
			<path
				fill={fill}
				d='M1610 1283 l-35 -24 -31 -183 c-29 -178 -30 -184 -12 -206 10 -12 18
-28 18 -35 0 -9 27 -19 73 -29 39 -9 113 -25 162 -35 l90 -20 148 147 c82 81
152 154 157 163 10 17 -9 26 -435 204 l-100 42 -35 -24z'
			/>
			<path
				fill={fill}
				d='M1140 1145 c-184 -79 -334 -146 -333 -149 1 -4 3 -10 5 -15 2 -5 126
-35 276 -66 l272 -56 31 25 c17 14 36 26 43 26 11 0 44 173 70 364 0 6 -6 11
-14 12 -8 2 -166 -62 -350 -141z'
			/>
			<path
				fill={fill}
				d='M174 951 l99 -100 36 15 c20 9 76 32 125 52 68 28 84 38 70 44 -20 8
-400 88 -418 88 -6 0 34 -45 88 -99z'
			/>
			<path
				fill={fill}
				d='M784 898 c-3 -4 109 -172 248 -373 l253 -365 22 23 c18 18 28 62 63
275 l42 254 -26 27 c-14 15 -26 32 -26 38 0 7 -26 17 -57 24 -355 74 -515 104
-519 97z'
			/>
			<path
				fill={fill}
				d='M450 851 c-47 -20 -94 -40 -104 -44 -17 -7 -14 -14 34 -62 30 -31 58
-54 61 -53 8 3 129 185 129 193 0 13 -42 1 -120 -34z'
			/>
			<path
				fill={fill}
				d='M558 737 l-66 -102 276 -278 c153 -153 289 -285 304 -293 15 -8 40
-14 56 -14 29 0 92 36 99 55 2 6 -109 172 -245 370 l-248 360 -55 2 -55 1 -66
-101z'
			/>
			<path
				fill={fill}
				d='M1507 728 c-22 -20 -28 -43 -52 -198 -15 -96 -29 -188 -32 -205 -5
-25 25 0 191 165 108 107 196 197 196 200 0 5 -252 60 -272 60 -4 0 -18 -10
-31 -22z'
			/>
		</g>
	</svg>
);

export default SVG;
