import React from 'react';

const SVG = ({
	style = {},
	fill = '#000',
	width = '17.5px',
	height = '17.5px',
	className = '',
	viewBox = '0 0 2400 2654',
}) => (
	<svg
		version='1.0'
		xmlns='http://www.w3.org/2000/svg'
		style={style}
		// width='200.000000pt'
		width={width}
		// height='190.000000pt'
		height={height}
		// viewBox='0 0 200.000000 190.000000'
		viewBox={viewBox}
		className={`svg-icon ${className || ''}`}
		preserveAspectRatio='xMidYMid meet'>
		<metadata>
			Created by potrace 1.15, written by Peter Selinger 2001-2017
		</metadata>
		<g
			transform='translate(0.000000,2654.000000) scale(0.100000,-0.100000)'
			fill={fill}
			stroke='none'>
			<path
				fill={fill}
				d='M9603 25323 c3 -5 676 -1990 1497 -4413 821 -2423 1494 -4406 1495
-4408 1 -1 56 -16 121 -32 65 -16 154 -40 196 -53 l77 -25 258 522 c142 286
812 1640 1488 3008 676 1368 1231 2485 1233 2483 2 -2 669 -1361 1483 -3021
813 -1660 1481 -3019 1483 -3021 2 -3 78 19 168 47 89 28 174 54 187 58 23 6
137 338 1518 4412 822 2423 1496 4415 1499 4428 l6 22 -6357 0 c-3496 0 -6354
-3 -6352 -7z'
			/>
			<path
				fill={fill}
				d='M11810 15339 c-243 -25 -472 -95 -689 -210 -885 -470 -1240 -1559
-801 -2455 181 -371 470 -665 835 -849 590 -299 1299 -260 1852 100 115 75
179 127 292 238 290 284 474 647 536 1052 20 131 20 409 0 539 -80 525 -369
982 -809 1277 -357 240 -794 351 -1216 308z'
			/>
			<path
				fill={fill}
				d='M19850 15340 c-455 -50 -858 -249 -1171 -578 -737 -776 -664 -2020
161 -2707 231 -192 531 -336 820 -394 605 -120 1211 57 1658 483 309 296 502
678 558 1106 19 148 14 415 -10 548 -76 415 -259 753 -565 1044 -265 252 -623
428 -982 482 -131 20 -358 28 -469 16z'
			/>
			<path
				fill={fill}
				d='M3721 15319 c-461 -49 -920 -295 -1220 -654 -415 -498 -542 -1156
-344 -1774 46 -145 162 -377 250 -501 76 -106 241 -282 342 -364 260 -212 577
-351 912 -400 138 -21 397 -21 532 -1 697 105 1276 589 1497 1254 219 656 61
1363 -416 1870 -393 418 -972 630 -1553 570z'
			/>
			<path
				fill={fill}
				d='M15068 13465 c-5 -5 -8 -51 -8 -104 0 -105 -20 -298 -45 -431 -109
-575 -379 -1113 -770 -1530 -511 -545 -1146 -869 -1890 -966 -167 -22 -552
-23 -710 -1 -60 8 -110 14 -111 13 -1 0 -48 -73 -106 -162 -601 -921 -1414
-1824 -2293 -2545 -311 -255 -532 -419 -875 -648 -744 -496 -1475 -859 -2352
-1166 l-107 -37 -158 82 c-230 121 -300 162 -473 276 -889 588 -1487 1469
-1679 2476 -37 192 -53 349 -58 580 -6 257 5 435 44 663 21 127 81 393 100
441 4 10 -3 14 -25 14 -99 0 -457 84 -646 151 -61 22 -113 36 -117 32 -7 -7
-63 -122 -436 -898 -612 -1270 -894 -2057 -1032 -2881 -238 -1421 -82 -2709
439 -3631 286 -506 640 -883 1137 -1213 633 -421 1275 -653 2008 -727 218 -22
662 -21 890 1 727 71 1441 258 2335 611 688 271 1368 586 2705 1253 259 129
533 265 610 302 77 37 239 116 360 175 948 462 1813 834 2480 1067 94 32 179
63 191 67 11 5 121 41 245 79 795 250 1239 343 2559 537 322 47 704 106 850
130 754 126 1298 271 1768 472 1633 697 2521 2321 2206 4033 -35 187 -66 306
-134 510 -52 157 -168 442 -188 462 -4 4 -37 -12 -73 -36 -297 -200 -689 -363
-1047 -437 -661 -136 -1334 -58 -1935 225 -1018 479 -1708 1500 -1763 2612
l-7 131 -526 6 c-289 4 -713 10 -941 13 -228 4 -418 3 -422 -1z'
			/>
		</g>
	</svg>
);

export default SVG;
