import React from 'react';

const SVG = ({
	style = {},
	fill = '#000',
	width = '38.5px',
	height = '17.5px',
	className = '',
	viewBox = '0 0 1200 546',
}) => (
	<svg
		version='1.0'
		xmlns='http://www.w3.org/2000/svg'
		style={style}
		// width='200.000000pt'
		width={width}
		// height='190.000000pt'
		height={height}
		// viewBox='0 0 200.000000 190.000000'
		viewBox={viewBox}
		className={`svg-icon ${className || ''}`}
		preserveAspectRatio='xMidYMid meet'>
		<metadata>
			Created by potrace 1.15, written by Peter Selinger 2001-2017
		</metadata>
		<g
			transform='translate(0.000000,546.000000) scale(0.100000,-0.100000)'
			fill={fill}
			stroke='none'>
			<path
				fill={fill}
				d='M0 2730 l0 -2730 6000 0 6000 0 0 2730 0 2730 -6000 0 -6000 0 0
-2730z m2490 461 l0 -1339 -27 -6 c-51 -11 -277 -45 -378 -57 -155 -19 -519
-17 -640 4 -341 60 -562 203 -679 440 -133 270 -135 649 -6 925 142 303 408
476 788 513 109 11 263 3 340 -17 l22 -6 0 441 0 441 290 0 290 0 0 -1339z
m920 1034 l0 -295 -290 0 -290 0 0 295 0 295 290 0 290 0 0 -295z m1245 -555
c281 -32 422 -127 484 -325 13 -43 16 -156 18 -782 l4 -732 -23 -5 c-82 -18
-367 -44 -604 -56 -644 -33 -897 118 -898 535 -1 298 150 474 490 574 109 32
300 66 427 77 l79 7 -4 98 c-3 87 -6 100 -27 123 -37 40 -90 49 -226 42 -184
-9 -363 -53 -533 -131 -41 -19 -78 -32 -83 -29 -5 3 -9 99 -9 214 0 171 3 211
15 223 8 9 56 31 107 51 265 101 544 143 783 116z m1845 0 c276 -29 449 -115
552 -275 50 -77 75 -163 88 -300 15 -164 13 -1278 -2 -1293 -18 -18 -551 -17
-566 1 -8 9 -12 203 -14 633 l-3 621 -30 48 c-21 35 -45 56 -80 74 -44 23 -61
26 -160 26 -70 0 -138 -7 -187 -18 l-78 -18 -2 -687 -3 -687 -285 0 -285 0 0
875 0 875 65 17 c315 86 748 133 990 108z m4003 -5 c306 -53 534 -232 645
-505 110 -272 101 -650 -23 -916 -118 -256 -348 -428 -642 -479 -106 -19 -322
-19 -423 -1 -384 69 -637 325 -705 715 -19 108 -19 320 0 438 61 376 298 643
650 732 107 26 119 28 276 30 83 0 170 -5 222 -14z m-1431 -19 c131 -19 317
-51 322 -57 2 -2 -40 -96 -93 -209 l-97 -205 -69 3 -68 4 6 -84 c14 -181 20
-1151 8 -1288 -29 -331 -96 -510 -255 -678 -146 -155 -338 -245 -599 -283
-119 -17 -381 -14 -510 5 -101 16 -288 65 -329 87 -17 9 -18 29 -18 274 0 146
2 265 5 265 3 0 27 -11 53 -24 262 -132 663 -166 866 -73 98 45 170 129 202
235 14 43 36 223 29 230 -2 2 -34 -8 -73 -21 -147 -54 -381 -61 -547 -17 -237
61 -424 227 -515 453 -100 250 -86 605 31 840 157 313 472 498 949 556 117 15
566 6 702 -13z m-5667 -861 c0 -749 -3 -864 -18 -971 -53 -379 -192 -597 -488
-768 l-96 -56 -266 127 c-147 69 -267 129 -267 132 0 4 30 22 68 40 176 87
301 196 366 320 112 214 125 358 126 1372 0 355 3 649 7 652 4 4 133 6 287 5
l281 -3 0 -850z'
			/>
			<path
				fill={fill}
				d='M1594 3196 c-232 -53 -343 -226 -332 -514 7 -170 57 -284 155 -356
85 -63 157 -81 318 -81 77 0 148 4 158 7 16 7 17 39 17 467 l0 460 -37 11
c-63 18 -212 21 -279 6z'
			/>
			<path
				fill={fill}
				d='M4505 2564 c-116 -18 -220 -49 -268 -80 -56 -37 -77 -77 -77 -151 0
-52 4 -66 27 -94 51 -61 137 -77 314 -59 179 17 159 -9 159 211 l0 189 -32 -1
c-18 -1 -73 -8 -123 -15z'
			/>
			<path
				fill={fill}
				d='M10178 3200 c-75 -22 -155 -99 -194 -185 -36 -79 -54 -176 -54 -300
0 -187 39 -312 125 -404 63 -67 125 -93 227 -93 68 0 87 4 143 31 137 68 205
223 205 466 0 253 -84 429 -230 480 -45 16 -176 19 -222 5z'
			/>
			<path
				fill={fill}
				d='M8278 3184 c-176 -42 -301 -159 -343 -319 -19 -74 -20 -261 -1 -330
31 -119 101 -206 199 -251 82 -37 216 -44 322 -17 l70 18 -3 380 c-1 209 -5
415 -8 458 l-6 77 -87 -1 c-47 0 -112 -7 -143 -15z'
			/>
		</g>
	</svg>
);

export default SVG;
