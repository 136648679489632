import React from 'react';

const SVG = ({
	style = {},
	fill = '#000',
	width = '43px',
	height = '17.5px',
	className = '',
	viewBox = '0 0 392 160',
}) => (
	<svg
		version='1.0'
		xmlns='http://www.w3.org/2000/svg'
		style={style}
		// width='200.000000pt'
		width={width}
		// height='190.000000pt'
		height={height}
		// viewBox='0 0 200.000000 190.000000'
		viewBox={viewBox}
		className={`svg-icon ${className || ''}`}
		preserveAspectRatio='xMidYMid meet'>
		<metadata>
			Created by potrace 1.15, written by Peter Selinger 2001-2017
		</metadata>
		<g
			transform='translate(0.000000,160.000000) scale(0.100000,-0.100000)'
			fill={fill}
			stroke='none'>
			<path
				fill={fill}
				d='M1635 982 c-60 -7 -105 -23 -135 -48 -16 -14 -30 -30 -30 -35 0 -5
17 -20 38 -34 l39 -25 44 20 c99 45 189 5 189 -83 0 -24 -2 -24 -112 -29 -177
-8 -220 -40 -226 -163 -4 -90 16 -131 78 -162 54 -28 177 -32 224 -8 35 18 36
18 36 0 0 -12 13 -15 61 -15 l61 0 -4 218 c-4 199 -6 220 -26 259 -12 22 -33
50 -47 61 -42 33 -124 52 -190 44z m145 -392 c0 -52 -3 -61 -25 -76 -37 -24
-138 -23 -173 3 -39 29 -42 83 -6 112 23 18 40 21 115 21 l89 0 0 -60z'
			/>
			<path
				fill={fill}
				d='M2939 979 c-59 -9 -100 -30 -132 -68 -41 -49 -52 -96 -52 -226 0
-110 2 -125 26 -170 15 -30 42 -62 68 -80 36 -25 54 -30 118 -34 90 -4 141 9
188 50 53 47 70 104 70 239 0 134 -17 192 -70 238 -51 46 -131 65 -216 51z
m118 -129 c39 -23 53 -67 53 -165 -1 -125 -49 -186 -137 -172 -71 12 -103 68
-103 184 0 76 23 139 59 158 37 20 91 18 128 -5z'
			/>
			<path
				fill={fill}
				d='M3475 979 c-102 -15 -155 -73 -155 -171 0 -112 36 -143 183 -159 113
-13 145 -24 153 -55 14 -54 -30 -87 -116 -87 -46 0 -73 7 -125 32 -37 18 -67
31 -69 29 -1 -1 -16 -20 -34 -42 l-33 -38 32 -24 c66 -48 135 -66 239 -62 160
6 212 48 218 171 5 125 -34 160 -195 177 -122 13 -132 16 -139 45 -20 80 98
108 221 52 l50 -23 32 39 c18 21 33 43 33 47 0 14 -103 59 -150 65 -25 4 -54
8 -65 9 -11 2 -47 -1 -80 -5z'
			/>
			<path
				fill={fill}
				d='M1970 968 c1 -7 41 -71 90 -142 l89 -130 -95 -138 c-52 -76 -94 -142
-94 -148 0 -6 27 -10 63 -10 l62 0 48 73 c26 39 55 82 65 95 l18 24 63 -96 64
-96 64 0 c49 0 64 3 60 13 -2 7 -46 72 -97 145 l-92 134 91 137 c50 75 91 140
91 144 0 4 -29 7 -64 7 l-63 0 -38 -62 c-21 -35 -48 -78 -60 -96 l-22 -32 -59
95 -59 95 -62 0 c-44 0 -63 -4 -63 -12z'
			/>
			<path d='M2540 690 l0 -290 60 0 60 0 0 290 0 290 -60 0 -60 0 0 -290z' />
			<path
				fill={fill}
				d='M1120 922 l0 -42 -342 0 -343 0 40 -40 41 -40 382 2 382 3 -80 80
-80 80 0 -43z'
			/>
			<path
				fill={fill}
				d='M235 640 l80 -81 3 41 3 40 342 0 341 0 -39 40 -39 40 -385 0 -386 0
80 -80z'
			/>
		</g>
	</svg>
);

export default SVG;
